<template>
    <div class="document-page">
        <div class="header">
            <h1>Documents</h1>
            <div class="header-actions">
                <button
                    class="archive-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                    @click="toggleApprovedView"
                >
                    {{
                        showApproved
                            ? $t('documents.list.buttons.show_unapproved')
                            : $t('documents.list.buttons.show_approved')
                    }}
                </button>
                <button
                    class="archive-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                    @click="toggleArchivedView"
                >
                    {{ showArchived ? $t('documents.list.buttons.hide') : $t('documents.list.buttons.show') }}
                </button>
                <button
                    class="action-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                    @click="$router.push({ name: 'document.new' })"
                >
                    {{ $t('documents.list.buttons.create') }}
                </button>
            </div>
        </div>

        <div class="document-type-title">
            <h2>
                {{ showArchived ? 'Archived ' : '' }}
                {{ showApproved ? 'Approved ' : 'Unapproved ' }}
                Documents
            </h2>
        </div>

        <div v-if="!showArchived">
            <div v-if="documents.length" class="document-list">
                <ul class="documents">
                    <li v-for="(document, index) in documents" :key="index" class="document-item">
                        <div class="details">
                            <p>
                                <strong>{{ $t('documents.list.name') }}:</strong> {{ document.attributes.name }}
                            </p>
                            <p>
                                <strong>{{ $t('documents.list.main_company') }}:</strong>
                                {{ document.attributes.company.attributes.name }}
                            </p>
                            <p>
                                <strong>{{ $t('documents.list.partner_company') }}:</strong>
                                {{ document.attributes.partner_company.attributes.name }}
                            </p>
                            <p>
                                <strong>{{ $t('documents.list.version') }}:</strong> {{ document.attributes.version }}
                            </p>
                        </div>
                        <div class="actions">
                            <button
                                class="view-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                                @click="viewDocument(document)"
                            >
                                {{ $t('documents.list.buttons.view') }}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-else class="no-document-card">
                <h1>{{ $t('documents.list.not_found') }}</h1>
                <p>{{ $t('documents.list.not_found_message') }}</p>
            </div>
        </div>

        <div v-else>
            <div class="archived-document-list">
                <h2>{{ $t('documents.list.arc_title') }}</h2>
                <ul class="documents">
                    <li v-for="(document, index) in documents" :key="index" class="document-item">
                        <div class="details">
                            <p>
                                <strong>{{ $t('documents.list.name') }}:</strong> {{ document.attributes.name }}
                            </p>
                            <p>
                                <strong>{{ $t('documents.list.main_company') }}:</strong>
                                {{ document.attributes.company.attributes.name }}
                            </p>
                            <p>
                                <strong>{{ $t('documents.list.partner_company') }}:</strong>
                                {{ document.attributes.partner_company.attributes.name }}
                            </p>
                            <p>
                                <strong>{{ $t('documents.list.version') }}:</strong> {{ document.attributes.version }}
                            </p>
                        </div>
                        <div class="actions">
                            <button
                                class="view-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                                @click="viewDocument(document)"
                            >
                                {{ $t('documents.list.buttons.view') }}
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import DocumentService from '../../services/document.service';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DocumentListPage',
        data() {
            return {
                documents: [],
                showArchived: false,
                showApproved: true,
                service: new DocumentService(),
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        created() {
            this.fetchDocuments();
        },
        methods: {
            viewDocument(document) {
                this.$router.push({ name: 'document.view', params: { document_id: document.attributes.id } });
            },
            toggleArchivedView() {
                this.showArchived = !this.showArchived;
                this.fetchDocuments();
            },
            toggleApprovedView() {
                this.showApproved = !this.showApproved;
                this.fetchDocuments();
            },
            async fetchDocuments() {
                try {
                    const { data } = await this.service.getAll({
                        archived: this.showArchived,
                        partner_company_approved: this.showApproved,
                        client_id: this.$route.params.id,
                        company_id: this.company.id,
                    });
                    this.documents = data.data;
                } catch (error) {
                    console.log('Error fetching documents:', error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .document-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f4f6f8;
        font-family: Arial, sans-serif;
        padding: 20px;
        min-height: 100vh;
        border-radius: 5px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        width: 100%;
        max-width: 700px;
        margin-bottom: 20px;
    }

    .header h1 {
        margin: 0;
        font-size: 1.8rem;
        color: #333;
    }

    .header-actions {
        display: flex;
        gap: 10px;
    }

    .document-type-title h2 {
        font-size: 1.6rem;
        color: #555;
        margin-bottom: 10px;
    }

    .document-list,
    .archived-document-list {
        width: 700px;
        background-color: #ffffff;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid #ddd;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    }

    .documents {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .document-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        margin-bottom: 10px;
        background: #fafafa;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
        transition: background-color 0.3s, box-shadow 0.3s;
        cursor: pointer;
    }

    .document-item:hover {
        background: linear-gradient(135deg, #e3f2fd, #e0f7fa);
        box-shadow: 0 8px 16px rgba(0, 123, 255, 0.1);
        transform: scale(1.01);
    }

    .details {
        text-align: left;
        font-size: 0.95rem;
        color: #444;
    }

    .actions {
        display: flex;
        gap: 10px;
    }

    .theme-dark .document-page {
        background-color: #2b2b2b;
    }

    .theme-dark .document-type-title h2 {
        color: #e0e0e0;
    }

    .theme-dark .document-list,
    .theme-dark .archived-document-list {
        background-color: #3c3c3c;
        border-color: #444;
        color: #e0e0e0;
    }

    .theme-dark .document-item {
        background-color: #333;
    }

    .theme-dark .document-item:hover {
        background: linear-gradient(135deg, #333, #222);
        box-shadow: 0 8px 16px rgba(255, 255, 255, 0.1);
    }
</style>
