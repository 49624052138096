<template>
    <div class="order-form">
        <h2>{{ $t('documents.new.title') }}</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group" :class="{ 'has-error': errors.name }">
                <label for="name">{{ $t('documents.new.name') }}</label>
                <input id="name" v-model="form.name" type="text" />
                <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
            </div>
            <div class="form-group">
                <label for="company">{{ $t('documents.new.company') }}</label>
                <input id="company" v-model="companyName" type="text" readonly />
            </div>
            <div class="form-group">
                <label for="partnerCompany">{{ $t('documents.new.partner') }}</label>
                <input id="partnerCompany" v-model="partherCompanyName" type="text" readonly />
                <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.file }">
                <label for="agreementFile">{{ $t('documents.new.file') }}</label>
                <input id="agreementFile" type="file" @change="handleFileUpload" />
                <span v-if="errors.file" class="error-message">{{ errors.file }}</span>
            </div>
            <button type="submit" class="submit-button">{{ $t('documents.new.buttons.submit') }}</button>
        </form>
    </div>
</template>

<script>
    import ClientService from '../../../Client/services/client.service';
    import DocumentService from '../../services/document.service';
    import { mapGetters } from 'vuex';

    export default {
        name: 'DocumentForm',
        props: {
            documentID: [Number, String],
        },
        data() {
            return {
                companyName: '',
                partherCompanyName: '',
                partnerCompany: null,
                document: null,
                form: {
                    name: '',
                    company_id: '',
                    partner_company_id: '',
                    file: null,
                },
                errors: {},
                clientService: new ClientService(),
                service: new DocumentService(),
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        async created() {
            if (this.documentID != null) {
                await this.fetchDocument();
                return this.fillFromDocument();
            }
            await this.fetchClientCompanies();
            this.fillFormData();
        },
        methods: {
            handleFileUpload(event) {
                const file = event.target.files[0];
                if (file) {
                    this.form.file = file;
                }
            },
            async fetchClientCompanies() {
                try {
                    const { data } = await this.clientService.getItem(this.$route.params.id);
                    this.partnerCompany = data.data.attributes;
                } catch (error) {
                    console.log('Error fetching Client Data', error);
                }
            },
            handleSubmit() {
                this.clearErrors();
                if (this.validateForm()) {
                    this.saveForm();
                }
            },
            async saveForm() {
                try {
                    const formData = new FormData();
                    formData.append('document[company_id]', this.form.company_id);
                    formData.append('document[partner_company_id]', this.form.partner_company_id);
                    formData.append('document[name]', this.form.name);
                    formData.append('document[file]', this.form.file);

                    const res = await this.service.save(formData);

                    if (res.status == 201) {
                        this.$Notify({
                            type: 'success',
                            title: this.$t('notification.save.success.title'),
                            message: this.$t('notification.save.success.message'),
                        });

                        this.$router.push({ name: 'document.list' });
                    }
                } catch (error) {
                    console.error('Service request error', error);
                }
            },
            validateForm() {
                let valid = true;
                if (!this.form.name) {
                    this.errors.name = 'Document name is required';
                    valid = false;
                }
                if (!this.form.file) {
                    this.errors.file = 'File upload is required';
                    valid = false;
                }
                if (!this.partherCompanyName) {
                    this.errors.file = 'Partner Company name is required';
                    valid = false;
                }
                return valid;
            },
            clearErrors() {
                this.errors = {};
            },
            fillFormData() {
                this.companyName = this.company.name;
                this.partherCompanyName = this.partnerCompany.name;

                this.form.company_id = this.company.id;
                this.form.partner_company_id = this.partnerCompany.company_id;
            },
            async fetchDocument() {
                try {
                    const { data } = await this.service.getItem(this.$route.params.document_id);
                    this.document = data.data.attributes;
                } catch (error) {
                    console.log('Error fetching Client Companies', error);
                }
            },
            fillFromDocument() {
                this.companyName = this.document.company.attributes.name;
                this.partherCompanyName = this.document.partner_company.attributes.name;

                this.form.name = this.document.name;
                this.form.company_id = this.document.company.attributes.id;
                this.form.partner_company_id = this.document.partner_company.attributes.id;
            },
        },
    };
</script>

<style scoped lang="scss">
    .order-form {
        max-width: 450px;
        margin: 50px auto;
        padding: 25px;
        background: linear-gradient(135deg, #f7f9fc, #e2ebf0);
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        font-family: 'Arial', sans-serif;

        .theme-dark & {
            background: #222;
            color: #ffa500;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
        }

        h2 {
            text-align: center;
            color: #333;

            .theme-dark & {
                color: #ffa500;
            }
        }
    }

    .form-group {
        margin-bottom: 20px;
        position: relative;
    }

    label {
        display: block;
        font-weight: bold;
        color: #555;
        margin-bottom: 8px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    select,
    input[type='number'],
    input[type='text'],
    input[type='date'],
    input[type='file'] {
        width: 100%;
        padding: 12px 16px;
        font-size: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            outline: none;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;

        .theme-dark & {
            color: #ff4c4c;
        }
    }

    .submit-button {
        width: 100%;
        background-image: linear-gradient(to right, #4caf50, #81c784);
        color: white;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-image 0.3s, box-shadow 0.3s;

        &:hover {
            background-image: linear-gradient(to right, #388e3c, #66bb6a);
            box-shadow: 0 8px 15px rgba(0, 128, 0, 0.2);
        }

        .theme-dark & {
            background-image: linear-gradient(to right, #ffa500, #ffb84d);
            color: #222;
        }
    }

    input[type='date'] {
        padding: 10px 14px;
        font-size: 15px;
        color: #333;
        appearance: none;

        .theme-dark & {
            color: #ffa500;
        }
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        background-color: #4caf50;
        padding: 5px;
        border-radius: 50%;
        cursor: pointer;

        .theme-dark & {
            background-color: #ffa500;
        }
    }

    .form-group.has-error input,
    .form-group.has-error select {
        border-color: #e74c3c;

        .theme-dark & {
            border-color: #ff4c4c;
        }
    }

    @media (max-width: 500px) {
        .order-form {
            padding: 15px;
        }

        h2 {
            font-size: 1.5em;
        }
    }
</style>
